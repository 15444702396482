<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="m8 1 5 5m0 0-5 5m5-5H1" />
    </svg>
</template>
